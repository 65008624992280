import styles from '@styles/user/login.module.scss';

interface Props {
    id: string;
    text: string;
    onClick?: () => void;
}

const LoginButton = ({ id, text, onClick }: Props) => {
    return (
        <button type="button" id={id} disabled={!onClick} onClick={onClick} className={styles.login_btn}>
            {text}
        </button>
    );
};

export default LoginButton;
