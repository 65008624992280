import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import LoginButton from '@feature/user/login/components/LoginButton';
import styles from '@styles/common2.module.scss';
import Bell from '@assets/bell.svg';

interface Props {
    email: string;
    type: string;
    onClose: () => void;
}

const LoginAlertModal = ({ email, type, onClose }: Props) => {
    const { t, locale } = useTranslation('login');
    const text = t('loginAlertModal');

    const router = useRouter();

    const socialObj: { [key: string]: string } = { naver: '네이버', kakao: '카카오', google: '구글', wavedeck: '볼리' };

    const onLoginBtnClick = () => {
        let link: string | undefined;
        if (type === 'wavedeck') {
            router.push('/login/email');
            onClose();
            return;
        }
        if (type === 'naver') {
            link =
                process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                    ? process.env.NEXT_PUBLIC_LOGIN_NAVER_LINK_DEV
                    : process.env.NEXT_PUBLIC_LOGIN_NAVER_LINK;
        } else if (type === 'kakao') {
            link =
                process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                    ? process.env.NEXT_PUBLIC_LOGIN_KAKAO_LINK_DEV
                    : process.env.NEXT_PUBLIC_LOGIN_KAKAO_LINK;
        } else if (type === 'google') {
            link =
                process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                    ? process.env.NEXT_PUBLIC_LOGIN_GOOGLE_LINK_DEV
                    : process.env.NEXT_PUBLIC_LOGIN_GOOGLE_LINK;
        }

        if (!link) return;
        window.location.href = link;
    };

    return (
        <div role="presentation" className={styles.background_modal} onClick={onClose}>
            <div role="presentation" className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <div className={styles.content}>
                    <div className={styles.title_area}>
                        <Bell />
                        <p className={styles.title}>{text.title}</p>
                    </div>
                    <span className={styles.login_guide}>
                        {text.guide[0]}
                        {email}
                        {text.guide[1]}
                        <b>
                            {locale === 'ko' ? socialObj[type] : type}
                            {text.guide[2]}
                        </b>
                        {text.guide[3]}
                        <br />
                        {text.guide[4]}
                    </span>
                    <LoginButton
                        id={type}
                        text={locale === 'ko' ? `${socialObj[type]}${text.start}` : `${text.start} ${type}`}
                        onClick={onLoginBtnClick}
                    />
                </div>
            </div>
        </div>
    );
};
export default LoginAlertModal;
